import React, { useState } from "react";
import { IconButton, Tooltip, withStyles } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import CheckCircle from "@material-ui/icons/CheckCircle";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#172B4D",
    color: 'rgba(0, 0, 0, 0.87)',
    borderRadius: "8px",
            
              padding: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              fontFamily: "Poppins",
              fontSize: "12px",
   
    border: '1px solid #dadde9',
    minWidth: 200,
  },
}))(Tooltip);

const InfoTooltip = ({ title, validationStates }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipOpen = () => setTooltipOpen(true);
  const handleTooltipClose = () => setTooltipOpen(false);

  return (
    <HtmlTooltip
      onMouseOut={handleTooltipClose}
      open={tooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={
        <React.Fragment>
          <div
            style={{
              backgroundColor: "#172B4D",
               color: "#FFFFFF",
              borderRadius: "8px",
              width: "200px",
              padding: "8px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              fontFamily: "Poppins",
              fontSize: "12px",
            }}
          >
            <table style={{ width: "100%" }}>
              <tbody>
                {Object.entries(validationStates).map(([key, isValid]) => (
                  <tr key={key}>
                    <td style={{ width: "90%" }}>{key}</td>
                    <td style={{ width: "10%" }} align="center">
                      {isValid && (<CheckCircle  fontSize="small" style={{ color: "#9b51e0", fontSize: "12px" }} />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </React.Fragment>
      }
    >
      <IconButton onMouseOver={handleTooltipOpen}>
        <InfoIcon fontSize="small" style={{ color: "gray" }} />
      </IconButton>
    </HtmlTooltip>
  );
};

export default InfoTooltip;
